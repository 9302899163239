import React from "react";
import Home from "../component/Home/Home";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import "../styles/global.css";
import citySpecificImage from "../images/cities/del_mar.png";

const App = () => {
  const pageData = {
    residentialRealtors: {
      title: "Realtors in Del Mar, CA",
      subtitle: "With Your Best Interests At Heart",
      description:
        "This is about more than a deal—it’s about your family's happiness and building a great future. Whether you’re buying, selling, or investing, our experienced residential real estate professionals will connect you with the best opportunities for real estate in Del Mar, CA. And we'll do it with heart because we're a family too.",
    },
    findNextHome: {
      card1Title: "Del Mar Homes for Sale",
      card2Title: "What Is Your Home Worth?",
    },
    meetFamily: {
      title: "Meet Our Family",
      subtitle:
        "We’re dedicated realtors who help families like yours throughout the homeownership journey—from buying to selling to investing. We’re Del Mar real estate advisors who are in your corner from day one.",
    },
    citySpecific: {
      titlePartA: "Proudly Assisting",
      titlePartB: "Del Mar",
      p1: "A safe beach town in stunning San Diego County, Del Mar is an idyllic place to call home. With abundant activities available and nearly year-round sunshine, it’s the perfect place for families, working professionals, and retirees. Highlights of this picturesque city include the Del Mar City Beach, Seagrove Park, and the Del Mar Race Track.",
      p2: "Looking for homes for sale in Del Mar, CA, so you too can enjoy a beach lifestyle filled with endless entertainment options? Our Selby team is here to help you find your dream home. Or perhaps you’re ready for a change and are interested in selling or would like to explore houses for sale in Del Mar that you can invest in. We can assist with that, too. Get in touch with us today!",
      image: citySpecificImage,
    },
    cta: {
      description:
        "Connect with a top-rated team of Del Mar realtors who listen, are readily available, and will work tirelessly for you.",
    },
  };
  return (
    <LayoutWrapper
      title="Del Mar Homes for Sale | Real Estate | Selby Realtors"
      desc="Find homes for sale in Del Mar, where life is about sun, surf, and great vibes. Call The Selby Team to learn more about this San Diego beach community!"
      headerLogo="../../images/Selby_logo.svg"
    >
      <Home pageData={pageData} />
    </LayoutWrapper>
  );
};

export default App;
